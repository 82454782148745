import { useContext, useMemo, useEffect, useState, useRef } from 'react';
import useQuery from 'src/hooks/useQuery';
import Item from './Item';
import { ApiRoutes } from 'src/lib/routes';
import { Tag, OptionLocation, FilterOption, City } from 'src/types';
import useFilter from 'src/hooks/useFilter';
import { uniqBy } from 'lodash-es';
import { sortOptions, timeOfDayOptions, childAgeOptions } from 'src/lib/constants';
import AllItem from './AllItem';
import useBreakpoints from 'src/hooks/useBreakpoints';
import classNames from 'classnames';
import FilterItemProvider, { FilterItemContext } from 'src/contexts/FilterItemProvider';

const FilterInner: React.FC = () => {
  const { showFilterItem } = useContext(FilterItemContext)
  const [show, setShow] = useState(true);
  const prevPosRef = useRef(0);
  const { isScreenMd } = useBreakpoints();
  const { filterRecord } = useFilter();
  const { data } = useQuery<{
    activityTypes: Tag[];
    locations: OptionLocation[];
    cities: City[];
    suitables: Tag[];
    prices: [number, number];
  }>(
    filterRecord
      ? ApiRoutes.apiFilterItemsRoute(
          {},
          { record_id: filterRecord.id, record_type: filterRecord.type }
        ).toUrl()
      : null
  );
  const { activityTypes, locations, cities, suitables, prices } = data || {
    activityTypes: [],
    locations: [],
    cities: [],
    suitables: [],
    prices: [],
  };

  const formattedActivityTypes: FilterOption[] = useMemo(
    () =>
      uniqBy(
        activityTypes
          .filter((o) => !o.parentId)
          .concat(activityTypes.filter((o) => !!o.parent).map((o) => o.parent) as Tag[])
          .sort((a, b) => a.name.localeCompare(b.name)),
        'id'
      ).map((tag) => {
        return {
          label: tag.name,
          value: tag.id,
          children: activityTypes
            .filter((o) => o.parentId === tag.id)
            .map((o) => ({ label: o.name, value: o.id })),
        };
      }),
    [JSON.stringify(activityTypes)]
  );

  const formattedSuitables: FilterOption[] = useMemo(
    () => suitables.map((o) => ({ label: o.name, value: o.id })),
    [JSON.stringify(suitables)]
  );

  const formattedLocations: FilterOption[] = useMemo(
    () => locations.map((o) => ({ label: o.name, value: o.id })),
    [JSON.stringify(locations)]
  );

  const formattedCities: FilterOption[] = useMemo(
    () => cities.map((o) => ({ label: o.name, value: o.id })),
    [JSON.stringify(cities)]
  );

  useEffect(() => {
    prevPosRef.current = 0;
    setShow(true);

    const showFilter = () => {
      if (showFilterItem) return
      const currentPos = window.scrollY;
      if (prevPosRef.current > currentPos) {
        setShow(true);
      } else if (currentPos === 0) {
        setShow(true);
      } else if (currentPos > 72) {
        setShow(false);
      }
      prevPosRef.current = currentPos;
    };

    document.addEventListener('scroll', showFilter);

    return () => {
      document.removeEventListener('scroll', showFilter);
    };
  }, [filterRecord?.id, filterRecord?.type, showFilterItem]);

  return filterRecord ? (
    <div
      className={classNames('', {
        'tw-bg-brand-yellow': show,
      })}
    >
      <div className={classNames('container tw-font-styleSans tw-py-3 md:tw-py-4 tw-px-2 md:tw-px-4')}>
        <div
          className={classNames('tw-flex tw-items-center tw-justify-start tw--mx-2', {
            'tw-invisible': !show,
          })}
        >
          <Item
            inputType="radio"
            label="Sort By"
            fieldName="priceOrder"
            hideCounter={true}
            options={sortOptions}
            showFilter={show}
          />
          {activityTypes.length > 0 ? (
            <Item
              inputType="checkbox"
              fieldName="activityType"
              label="Activity"
              isNested
              options={formattedActivityTypes}
              showFilter={show}
            />
          ) : null}
          {isScreenMd ? (
            <>
              {cities.length > 1 ? (
                <Item
                  inputType="checkbox"
                  label="Location"
                  fieldName="cityIds"
                  options={formattedCities}
                  showFilter={show}
                />
              ) : locations.length > 0 ? (
                <Item
                  inputType="checkbox"
                  label="Location"
                  fieldName="locationIds"
                  options={formattedLocations}
                  showFilter={show}
                />
              ) : null}
              <Item
                inputType="checkbox"
                label="Time of Day"
                fieldName="time"
                options={timeOfDayOptions}
                showFilter={show}
              />
              <Item
                inputType="checkbox"
                label="Child Age"
                fieldName="childAge"
                options={childAgeOptions}
                showFilter={show}
              />
            </>
          ) : null}
          <AllItem
            suitables={formattedSuitables}
            activityTypes={formattedActivityTypes}
            locations={formattedLocations}
            cities={formattedCities}
            prices={prices}
            showFilter={show}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const Filter: React.FC = () => {
  return (
    <FilterItemProvider>
      <FilterInner />
    </FilterItemProvider>
  )
}

export default Filter;
