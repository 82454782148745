import React, { useEffect, useState } from 'react';
import useFilter from 'src/hooks/useFilter';
import { FilterParams } from 'src/types';
import { defaultValue as filterDefaultValue } from 'src/contexts/FilterProvider';

type Props = {
  fieldNames: (keyof FilterParams)[];
  className?: string;
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
}
const ControlButtons: React.FC<Props> = ({ fieldNames, className, setShowPopover }) => {
  const [shouldFilter, setShouldFilter] = useState(false)
  const { setFilterParams, filter } = useFilter()

  useEffect(() => {
    if (shouldFilter) {
      filter()
    }
  }, [shouldFilter])

  return (
    <div className={`tw-font-sans tw-w-full tw-bg-brand-yellow tw-flex tw-items-center tw-justify-between tw-text-sm ${className || ''}`}>
      <div
        className="tw-font-styleSans tw-font-semibold tw-text-brand-navy tw-underline tw-cursor-pointer"
        onClick={() => {
          setFilterParams((prev) => ({
            ...prev,
            ...fieldNames.reduce((memo, key) => {
              memo[key] = filterDefaultValue[key]
              return memo
            }, {} as { [k: string]: any })
          }))
          setShowPopover(false)
          setShouldFilter(true)
        }}
      >
        clear
      </div>
      <button
        className="btn btn-primary tw-cursor-pointer tw-text-sm"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          if (e.currentTarget instanceof HTMLButtonElement) {
            e.currentTarget.disabled = true;
          }
          setShowPopover(false)
          filter();
        }}
      >
        Apply
      </button>
    </div>
  )
}

export default ControlButtons
