import { useEffect, useState } from 'react';

export default function ClientOnlyComponent({
  children,
}: {
  children: JSX.Element;
}) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? children : null;
}
