import { useCallback } from 'react';
import useInitialData from 'src/hooks/useInitialData';
import { TMoney } from 'src/types';

const MASTER_CURRENCY = 'SGD';

export type CurrencyExchangeFunction = (args: { amount: TMoney; toCurrency?: string }) => TMoney;

export default function useCurrencyExchange() {
  const { exchangeRates } = useInitialData();

  return useCallback<CurrencyExchangeFunction>(({ amount, toCurrency }) => {
    if (!toCurrency || amount.currency === toCurrency) return amount;

    if (amount.currency === MASTER_CURRENCY || toCurrency === MASTER_CURRENCY) {
      const exchangeRate = exchangeRates.find(
        (obj) => obj.fromCurrency === amount.currency && obj.toCurrency === toCurrency
      );

      if (!exchangeRate) {
        return amount;
      }

      return {
        amount: Number((Number(amount.amount) * Number(exchangeRate.rate)).toFixed(2)),
        currency: toCurrency,
      } as TMoney;
    } else {
      const masterRate = exchangeRates.find(
        (obj) => obj.fromCurrency === amount.currency && obj.toCurrency === MASTER_CURRENCY
      );

      if (!masterRate) {
        return amount;
      }

      const masterAmount = {
        amount: Number((Number(amount.amount) * Number(masterRate.rate)).toFixed(2)),
        currency: MASTER_CURRENCY,
      };
      const exchangeRate = exchangeRates.find(
        (obj) => obj.fromCurrency === masterAmount.currency && obj.toCurrency === toCurrency
      );

      if (!exchangeRate) {
        return amount;
      }

      return {
        amount: Number((Number(masterAmount.amount) * Number(exchangeRate.rate)).toFixed(2)),
        currency: toCurrency,
      } as TMoney;
    }
  }, [exchangeRates.length]);
}
