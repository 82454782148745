import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { FiX } from 'react-icons/fi'
import useFlash from 'src/hooks/useFlash'
import ClientOnlyPortal from './ClientOnlyPortal'
import { isEmpty } from 'lodash-es';

const Flash = () => {
  const [flash, setFlash] = useFlash()

  useEffect(() => {
    if (!isEmpty(flash)) {
      let timer = setTimeout(() => setFlash({}), flash.ttl || 5000)
      return () => clearTimeout(timer)
    }
  }, [JSON.stringify(flash)])

  return (
    <ClientOnlyPortal selector="#bottom-body">
      <Alert show={!!(flash.alert || flash.notice)} variant={flash.alert ? 'danger' : 'success'}>
        {flash.alert ? 'ERROR: ' : ''}{ flash.alert || flash.notice }
        <button type="button" className="btn close" onClick={() => setFlash({})}>
          <FiX size={24} className={flash.alert ? 'tw-text-red' : 'tw-text-brand-navy'} />
        </button>
      </Alert>
    </ClientOnlyPortal>
  )
}

export default Flash
