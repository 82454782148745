import React from 'react';
import { FiX } from 'react-icons/fi';
import ClientOnlyPortal from './ClientOnlyPortal';
import classNames from 'classnames';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BackpackSvg from 'public/images/svg_icons/backpack.svg';
import useCart from 'src/hooks/useCart';
import { ApiRoutes } from 'src/lib/routes';
import useQuery from 'src/hooks/useQuery';
import PurchaseItem from 'src/components/PurchaseItem';
import { AppRoutes } from 'src/lib/routes';
import Link from 'next/link';
import useCurrentCurrency from 'src/hooks/useCurrentCurrency';
import Money from 'src/components/Money';
import { GiftCard, Option } from 'src/types';
import Loading from 'src/components/Loading';
import { useRouter } from 'next/router';
import cartPopupStyle from 'src/styles/modules/components/cart_popup.module.scss';

export const CartPopup: React.FC<{
  isShowCart: boolean;
  isEventHeader?: boolean;
  isShowHeader: boolean;
  onClick: () => void;
}> = ({ isShowCart, isEventHeader, isShowHeader, onClick }) => {
  const [cart] = useCart();
  const orderItem = cart.orderItem;
  const currency = useCurrentCurrency();
  const { data: option, isLoading: isLoadingOption } = useQuery<Option>(
    cart.orderItem?.supplierActivityOptionId
      ? ApiRoutes.apiOptionRoute(
          { id: cart.orderItem.supplierActivityOptionId },
          { view: 'with_required_customer_info' }
        ).toUrl()
      : null
  );
  const { data: giftCard, isLoading: isLoadingGiftCard } = useQuery<GiftCard>(
    cart.orderItem?.giftCardId
      ? ApiRoutes.apiGiftCardRoute({ id: cart.orderItem.giftCardId }).toUrl()
      : null
  );

  return (
    <>
      <div
        className={classNames(
          'tw-fixed tw-right-0 tw-w-screen md:tw-max-w-sm tw-z-1200',
          {
            'tw-hidden': !isShowCart,
            [cartPopupStyle.cartWithEventHeaderContainer]: isShowHeader && isEventHeader,
            [cartPopupStyle.cartContainer]: isShowHeader && !isEventHeader,
            [cartPopupStyle.cartWithNoHeaderContainer]: !isShowHeader,
          }
        )}
      >
        <div
          className="tw-bg-brand-yellow md:tw-px-4 tw-px-5 tw-flex tw-overflow-y-auto custom-scrollbar tw-justify-between tw-pb-8 tw-flex-col tw-h-full"
        >
          {isLoadingOption || isLoadingGiftCard ? (
            <Loading className="tw-justify-center tw-mt-8" />
          ) : (
            <>
              <div>
                <div className="tw-pt-4 tw-text-center tw-relative">
                  <div
                    className="tw-absolute tw-right-0 tw-cursor-pointer"
                    onClick={() => {typeof onClick === 'function' && onClick()}}
                  >
                    <FiX size={25} className="tw-text-brand-navy" />
                  </div>
                  <div className="tw-text-4xl tw-font-styleSans tw-font-semibold tw-mt-4">Your bag</div>
                  <div className="tw-my-4 tw-border-t tw-border-grey-lighter tw-border-solid"></div>
                </div>
                <div>
                  {orderItem ? (
                    <PurchaseItem
                      option={option}
                      giftCard={giftCard}
                      orderItem={orderItem}
                      isCheckout={true}
                      isInsideCartPopup={true}
                      renderAfterQuantity={() => {
                        return (
                          <span className="tw-text-base tw-font-styleSans tw-font-semibold tw-leading-none tw-text-orange">
                            <Money amount={cart.exchangedSubtotalAmountWithConversionFee} />
                          </span>
                        );
                      }}
                    >
                      <div className="tw-mb-6 tw-border-t md:tw-block tw-hidden tw-border-grey-lighter tw-border-solid"></div>
                    </PurchaseItem>
                  ) : (
                    <div className="tw-font-sans tw-text-sm tw-text-center tw-font-normal">{`Bag whispers: "Fill me!"`}</div>
                  )}
                </div>
              </div>
              <div className="tw-text-center">
                <div className="tw-flex tw-items-center tw-justify-center tw-mb-2 tw-mt-2 tw-text-brand-navy">
                  <div className="tw-font-normal tw-font-sans tw-text-sm tw-mr-6">Order Total</div>
                  <div className="tw-font-sans tw-text-brand-navy">
                    {orderItem ? (
                      <>
                        {cart.promotionCode ? (
                          <span className={classNames('tw-text-xs tw-font-normal tw-line-through tw-mr-2 tw-leading-none')}>
                            <Money amount={cart.exchangedSubtotalAmountWithConversionFee} />
                          </span>
                        ) : null}
                        <span className="tw-text-sm tw-font-semibold tw-leading-none">
                          <Money amount={cart.exchangedTotalAmountWithConversionFee} />
                        </span>
                      </>
                    ) : (
                      <span className="tw-text-sm tw-font-semibold tw-leading-none">
                        <Money amount={{ amount: 0, currency: currency }} />
                      </span>
                    )}
                  </div>
                </div>
                <Link href={AppRoutes.checkoutRoute().toPath()}>
                  <a className="btn btn-primary">Checkout</a>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const CartButton: React.FC<{ isShowCart: boolean; onClick: () => void }> = ({
  isShowCart,
  onClick,
}) => {
  const { isScreenMd } = useBreakpoints();
  const [cart, setCart, mutateCart] = useCart();
  const router = useRouter();

  return (
    <>
      <div
        className="tw-cursor-pointer"
        onClick={() => {
          if (router.pathname !== '/checkout') {
            mutateCart();
            typeof onClick === 'function' && onClick();
          }
        }}
      >
        <a className="tw-flex tw-items-center">
          <div className="position-relative">
            <BackpackSvg className="tw-text-brand-navy tw-align-top" width={20} height={20} />
            {cart?.orderItemId ? (
              <span className="tw-bg-orange tw-text-brand-navy tw-font-styleSans tw-font-semibold tw-text-xs tw-leading-4 tw-w-4 tw-h-4 tw-inline-block tw-text-center tw-rounded-full tw-absolute tw--top-1 tw--right-2">
                1
              </span>
            ) : null}
          </div>
        </a>
      </div>
      <ClientOnlyPortal selector="#bottom-body">
        {isScreenMd ? (
          <div
            className={classNames('tw-bg-black tw-bottom-0 tw-left-0 tw-opacity-30 tw-fixed tw-w-screen', { 'tw-hidden': !isShowCart })}
            style={{ height: '100vh' }}
          ></div>
        ) : null}
      </ClientOnlyPortal>
    </>
  );
};
