import { useState, useRef, useEffect, useContext } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Overlay, Popover, Modal } from 'react-bootstrap';
import { FilterOption } from 'src/types';
import { ItemInner } from './Item';
import {
  timeOfDayOptions,
  childAgeOptions,
  durationOptions,
  tripTypeOptions,
} from 'src/lib/constants';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { FiX } from 'react-icons/fi';
import useFilter from 'src/hooks/useFilter';
import { filterKeys } from 'src/contexts/FilterProvider';
import classNames from 'classnames';
import ControlButtons from 'src/components/filter/ControlButtons';
import { FilterItemContext } from 'src/contexts/FilterItemProvider';

type Props = {
  activityTypes: FilterOption[];
  suitables: FilterOption[];
  locations: FilterOption[];
  cities: FilterOption[];
  prices: number[];
  showFilter: boolean;
};

const Content: React.FC<Omit<Props, 'showFilter'>> = ({
  activityTypes,
  suitables,
  locations,
  cities,
  prices,
}) => {
  const { filterParams } = useFilter();

  return (
    <>
      <div className="tw-pt-3">
        <div className="tw-font-styleSans tw-font-semibold tw-text-lg tw-mb-4">Activity Type</div>
        <ItemInner
          options={activityTypes}
          isNested
          inputType="checkbox"
          maxNumShownOption={4}
          fieldName="activityType"
          prefixFieldName="all"
          currentValue={filterParams['activityType']}
        />
      </div>
      <div className="tw-my-6 tw-border-t tw-border-grey-lighter"></div>
      <div className="">
        <div className="tw-font-styleSans tw-font-semibold tw-text-lg tw-mb-4">Time of Day</div>
        <ItemInner
          options={timeOfDayOptions}
          inputType="checkbox"
          fieldName="time"
          prefixFieldName="all"
          currentValue={filterParams['time']}
        />
      </div>
      {cities.length > 1 || locations.length > 0 ? (
        <>
          <div className="tw-my-6 tw-border-t tw-border-grey-lighter"></div>
          <div className="">
            <div className="tw-font-styleSans tw-font-semibold tw-text-lg tw-mb-4">Location</div>
            {cities.length > 1 ? (
              <ItemInner
                options={cities}
                inputType="checkbox"
                maxNumShownOption={4}
                fieldName="cityIds"
                prefixFieldName="all"
                currentValue={filterParams['cityIds']}
              />
            ) : locations.length > 0 ? (
              <ItemInner
                options={locations}
                inputType="checkbox"
                maxNumShownOption={4}
                fieldName="locationIds"
                prefixFieldName="all"
                currentValue={filterParams['locationIds']}
              />
            ) : null}
          </div>
        </>
      ) : null}
      {suitables.length > 0 ? (
        <>
          <div className="tw-my-6 tw-border-t tw-border-grey-lighter"></div>
          <div className="">
            <div className="tw-font-styleSans tw-font-semibold tw-text-lg tw-mb-4">Suitable For</div>
            <ItemInner
              options={suitables}
              inputType="checkbox"
              fieldName="suitableFor"
              prefixFieldName="all"
              currentValue={filterParams['suitableFor']}
            />
          </div>
        </>
      ) : null}
      <div className="tw-my-6 tw-border-t tw-border-grey-lighter"></div>
      <div className="">
        <div className="tw-font-styleSans tw-font-semibold tw-text-lg tw-mb-4">Child Age</div>
        <ItemInner
          options={childAgeOptions}
          inputType="checkbox"
          fieldName="childAge"
          prefixFieldName="all"
          currentValue={filterParams['childAge']}
        />
      </div>
      <div className="tw-my-6 tw-border-t tw-border-grey-lighter"></div>
      <div className="">
        <div className="tw-font-styleSans tw-font-semibold tw-text-lg tw-mb-4">Duration</div>
        <ItemInner
          options={durationOptions}
          inputType="checkbox"
          fieldName="durations"
          prefixFieldName="all"
          currentValue={filterParams['durations']}
        />
      </div>
      <div className="tw-my-6 tw-border-t tw-border-grey-lighter"></div>
      <div className="">
        <div className="tw-font-styleSans tw-font-semibold tw-text-lg tw-mb-4">Trip Type</div>
        <ItemInner
          options={tripTypeOptions}
          inputType="radio"
          fieldName="tripType"
          prefixFieldName="all"
          currentValue={filterParams['tripType']}
        />
      </div>
    </>
  );
};

const Item: React.FC<Props> = ({
  activityTypes,
  suitables,
  locations,
  cities,
  prices,
  showFilter,
}) => {
  const { setShowFilterItem } = useContext(FilterItemContext)
  const { filter, shouldCloseFilterItem } = useFilter();
  const { isScreenMd } = useBreakpoints();
  const itemRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const fieldNames = filterKeys.filter((k) => !['cityId', 'countryId'].includes(k));

  useEffect(() => {
    if (!showFilter && show) {
      setShow(false);
    }
  }, [showFilter, show]);

  useEffect(() => {
    if (shouldCloseFilterItem) {
      setShow(false)
    }
  }, [shouldCloseFilterItem])

  useEffect(() => {
    setShowFilterItem(show)
  }, [show, setShowFilterItem])

  return (
    <>
      <div
        ref={itemRef}
        className={classNames(
          'tw-justify-center tw-flex-grow md:tw-flex-grow-0 tw-flex tw-items-center tw-leading-none tw-py-2 tw-px-3 md:tw-px-6 tw-rounded-4xl tw-border tw-cursor-pointer tw-mx-2 tw-bg-white tw-border-grey-lighter hover:tw-border-brand-navy'
        )}
        onClick={() => setShow(true)}
      >
        <span className="tw-mr-2 tw-text-sm md:tw-text-base tw-font-styleSans tw-font-semibold tw-lowercase">All Filters</span>
        <FiChevronDown className={classNames('tw-text-brand-navy')} />
      </div>

      {isScreenMd ? (
        <Overlay
          rootClose={true}
          target={itemRef}
          show={show}
          placement="bottom"
          onHide={() => {
            setShow(false);
            filter();
          }}
        >
          <Popover
            id={`popover-filter-item-all-filters`}
            className="tw-w-72 tw-py-3 tw-px-0 popover-no-arrow tw-font-styleSans"
          >
            <div className="md:tw-max-h-sm tw-overflow-y-auto tw-relative">
              <Popover.Content className="tw-px-3">
                <Content {...{ activityTypes, suitables, locations, cities, prices }} />
              </Popover.Content>
            </div>
            <ControlButtons
              setShowPopover={setShow}
              fieldNames={fieldNames}
              className="tw-pt-3 tw-border-t tw-border-grey-lighter tw-px-3"
            />
          </Popover>
        </Overlay>
      ) : (
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
            filter();
          }}
          className="tw-font-styleSans"
          dialogClassName="modal-dialog-bottom modal-almost-full-height--sp modal-dialog-scrollable modal-lg"
          contentClassName="tw-rounded-tl-2xl tw-rounded-tr-2xl"
        >
          <Modal.Header>
            <div className="modal-title tw-font-semibold">All Filters</div>
            <button type="button" className="close" onClick={() => setShow(false)}>
              <FiX strokeWidth={1} size={24} className="tw-text-brand-navy" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <Content {...{ activityTypes, suitables, locations, cities, prices }} />
          </Modal.Body>
          <Modal.Footer>
            <ControlButtons setShowPopover={setShow} fieldNames={fieldNames} />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Item;
