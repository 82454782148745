import useCurrentUser from 'src/hooks/useCurrentUser';
import useFlash from 'src/hooks/useFlash';
import useCsrfTokenUpdate from 'src/hooks/useCsrfTokenUpdate';
import useCart from 'src/hooks/useCart';
import useEnhanceApiClient from './useEnhanceApiClient';
import ApiClient from 'src/lib/api_client';
import { AppRoutes } from 'src/lib/routes';
import { useRouter } from 'next/router';

const useLogout = () => {
  const [currentUser, setCurrentUser, saveToken] = useCurrentUser();
  const [, setCart] = useCart();
  const [, setFlash] = useFlash();
  const updateCsrfToken = useCsrfTokenUpdate();
  const logout = useEnhanceApiClient(ApiClient.logout);
  const router = useRouter()

  return () => {
    updateCsrfToken()
      .then((token) => {
        logout({}, { 'X-CSRF-Token': token })
          .then((result) => {
            const [json] = result
            if ('error' in json) {
              throw new Error(`Couldn't logout for user ${currentUser?.email}`);
            } else {
              setTimeout(()=>{
                saveToken(null);
                setCurrentUser(undefined);
                setCart(null);
              }, 1)
            }
          })
          .catch(() => {
            throw new Error(`Couldn't logout for user ${currentUser?.email}`);
          });
      })
      .catch((e) => {
        throw e;
      });
  };
};

export default useLogout;
