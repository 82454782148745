import { ChatMessage } from 'src/types';

export const saveFailedMessage = (chatMessage: ChatMessage, chatConversationId: number) => {
  const groupedSendFailedMessages: { [key: number | string]: ChatMessage[] } = JSON.parse(window.localStorage.getItem('send_failed_messages') || '{}')
  groupedSendFailedMessages[String(chatConversationId)] = (groupedSendFailedMessages[String(chatConversationId)] || []).
    concat([chatMessage])
  window.localStorage.setItem('send_failed_messages', JSON.stringify(groupedSendFailedMessages))
}

export const removeFailedMessage = (nanoid: string, chatConversationId: number) => {
  const groupedSendFailedMessages: { [key: number | string]: ChatMessage[] } = JSON.parse(window.localStorage.getItem('send_failed_messages') || '{}')
  groupedSendFailedMessages[String(chatConversationId)] = (groupedSendFailedMessages[String(chatConversationId)] || []).filter((o) => {
    return o.nanoid !== nanoid
  })
  window.localStorage.setItem('send_failed_messages', JSON.stringify(groupedSendFailedMessages))
}
