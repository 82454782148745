import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import { Camelized } from 'humps';
import { ApiRoutes } from 'src/lib/routes';
import { User } from 'src/types';
import { KeyedMutator } from 'swr';
import useQuery from 'src/hooks/useQuery';
import { removeAccessToken, setAccessToken } from 'src/lib/access_token';
import { ApiError } from 'src/hooks/useQuery';

export const CurrentUserContext = React.createContext<
  [
    User | Camelized<User> | undefined,
    KeyedMutator<Camelized<User>>,
    (token: string | null) => void,
    ApiError | undefined,
    boolean
  ]
>([
  undefined,
  () =>
    new Promise<User | Camelized<User> | undefined>((resolve) => {
      resolve(undefined);
    }),
  () => {},
  undefined,
  false,
]);

const CurrentUserProvider: React.FC = ({ children }) => {
  const [, setToken] = useState<string | null>(null);
  const {
    data: user,
    mutate,
    error,
    isLoading,
  } = useQuery<User>(ApiRoutes.apiMeRoute({}, { view: 'with_credit' }).toUrl(), {
    fetcherOpts: {
      corsEnable: true,
    },
  });

  const saveToken = useCallback((token: string | null) => {
    setToken(token);
    if (token) {
      setAccessToken(token);
    } else {
      removeAccessToken();
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'object') {
      setToken(window.localStorage.getItem('ss_access_token'));
    }
  }, []);

  return (
    <CurrentUserContext.Provider value={[user, mutate, saveToken, error, isLoading]}>
      {children}
    </CurrentUserContext.Provider>
  );
};
export default CurrentUserProvider;
