import { useEffect, useState } from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';
import ApiClient from 'src/lib/api_client';

const useCheckUnreadMessage = (canCheck: boolean) => {
  const [currentUser] = useCurrentUser()
  const [hasUnreadMessage, setHasUnreadMessage] = useState(false)
  const checkUnreadMessage = useEnhanceApiClient(ApiClient.checkUnreadMessage)

  useEffect(() => {
    if (canCheck && currentUser?.id) {
      checkUnreadMessage().then((response) => {
        const [json] = response
        if (!('error' in json)) {
          setHasUnreadMessage(json.hasUnreadMessage)
        }
      })
    }
  }, [canCheck, currentUser?.id])

  return hasUnreadMessage
}

export default useCheckUnreadMessage
