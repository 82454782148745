import React from 'react'

type Props = {
  className?: string;
  text?: string;
}

const Loading: React.FC<Props> = ({ className, text }) => {
  return (
    <div className={`tw-flex tw-items-center ${className || ''}`}>
      <div className="spinner-border tw-text-brand-navy"></div>
      <div className="tw-ml-2">{ text || 'Loading...' }</div>
    </div>
  )
}

export default Loading
