import { useEffect } from 'react';
import notify, { googleNotifier } from 'src/lib/social_event_notify';
import { useRouter } from 'next/router';

const PageViewTracker = () => {
  const windowKey = typeof window === 'object' ? 1 : 0
  const router = useRouter()

  useEffect(() => {
    if (typeof window === 'object') {
      const searchParams = new URL(window.location.href).searchParams
      const queryUtm: { [key: string]: string | null } = {
        name: searchParams.get('utm_campaign'),
        source: searchParams.get('utm_source'),
        medium: searchParams.get('utm_medium'),
      }
      const localUtm = window.sessionStorage.getItem('ss_utm') ? JSON.parse(window.sessionStorage.getItem('ss_utm') || '{}') : {}
      const hasQueryUtm = Object.keys(queryUtm).some((k) => !!queryUtm[k])
      const utm = hasQueryUtm ? queryUtm : localUtm
      if (hasQueryUtm) {
        window.sessionStorage.setItem('ss_utm', JSON.stringify(queryUtm))
      }
      googleNotifier.setCampaign(utm)

      notify('google', 'PageView')
      notify('facebook', 'PageView')
      notify('tiktok', 'PageView')
    }
  }, [router, windowKey])

  return <></>
}

export default PageViewTracker
