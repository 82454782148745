import { useEffect } from 'react'

export default function useOnClickOutside(ref: React.MutableRefObject<HTMLElement | null>, handler: (eventTarget: HTMLElement | SVGElement) => void) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current) return

      if (event.target instanceof HTMLElement || event.target instanceof SVGElement) {
        if (ref.current.contains(event.target)) {
          return
        }

        handler(event.target)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [])
}
