import { useState, useEffect } from 'react';
import Image from 'next/future/image';
import { ImageProps } from 'next/future/image';
import { Image as TImage } from 'src/types';
import { getLocalFullUrl } from 'src/helpers';
import imgDefault from 'public/images/default.jpeg';

type ImageFieldName =
  | 'url'
  | 'xlUrl'
  | 'lgUrl'
  | 'mdUrl'
  | 'smUrl'
  | 'webpXlUrl'
  | 'webpLgUrl'
  | 'webpMdUrl'
  | 'webpSmUrl'
  | 'blurUrl';

const ImageWithFallback: <T extends TImage | undefined>(
  props: Omit<ImageProps, 'src'> & {
    record?: T;
  } & (T extends TImage
      ? {
          src?: ImageProps['src'];
          imgField: ImageFieldName;
        }
      : {
          src: ImageProps['src'] | undefined | null;
          imgField?: undefined;
        })
) => JSX.Element = ({ record, imgField, src, ...nextProps }) => {
  const [error, setError] = useState(false);

  const safeImgField = 'url' as ImageFieldName

  const actualSrc = error
    ? record && imgField
      ? getLocalFullUrl(record[safeImgField])
      : imgDefault
    : (record && imgField ? record[imgField] : src) || '';

  useEffect(() => {
    setError(false)
  }, [src])

  return (
    <Image
      {...nextProps}
      src={actualSrc}
      alt={nextProps.alt}
      placeholder={record?.blurBase64 ? 'blur' : 'empty'}
      blurDataURL={record?.blurBase64}
      unoptimized={
        typeof nextProps.unoptimized === 'boolean' ? nextProps.unoptimized : record ? true : false
      }
      onError={() => {
        setError(true);
      }}
    />
  );
};

export default ImageWithFallback;
