import React, { useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { useRouter } from 'next/router';
import { AdminRoutes, AppRoutes } from 'src/lib/routes';
import Link from 'next/link';
import UserAvatar from 'src/components/account/UserAvatar';
import useCurrentUser from 'src/hooks/useCurrentUser';
import PinPartnerSvg from 'public/images/svg_icons/pin_partner.svg';
import ToolSvg from 'public/images/svg_icons/tool.svg';
import User2Svg from 'public/images/svg_icons/user-2.svg';
import CreditCardSvg from 'public/images/svg_icons/credit_card.svg';
import AirplaneSvg from 'public/images/svg_icons/airplane.svg';
import SpeakerSvg from 'public/images/svg_icons/speaker.svg';
import LogoutSvg from 'public/images/svg_icons/logout.svg';
import ChatSvg from 'public/images/svg_icons/chat.svg';
import useLogout from 'src/hooks/useLogout';
import ClientOnlyPortal from './ClientOnlyPortal';
import useBreakpoints from 'src/hooks/useBreakpoints';
import classNames from 'classnames';
import useCheckUnreadMessage from 'src/hooks/useCheckUnreadMessage';

type Props = {};

const ProfileDropdown: React.FC<Props> = () => {
  const { isScreenMd } = useBreakpoints()
  const [show, setShow] = useState(false);
  const buttonRef = useRef(null);
  const eleRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const [currentUser] = useCurrentUser();
  const logout = useLogout();
  const hasUnreadMessage = useCheckUnreadMessage(show)

  useOnClickOutside(eleRef, (eventTarget) => {
    if (overlayRef.current?.contains(eventTarget)) {
      return;
    }
    setShow(false);
  });

  useEffect(() => {
    if (router.isReady) {
      router.events.on('routeChangeComplete', () => {
        setShow(false);
      });
    }
  }, [router]);

  return currentUser ? (
    <>
      <div ref={eleRef}>
        <div
          className="tw-cursor-pointer"
          ref={buttonRef}
          onClick={() => setShow(true)}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <UserAvatar
            currentUser={currentUser}
            className="tw-h-7 tw-w-7"
            borderClassName="tw-border-2 tw-border-orange tw-border-solid"
          />
        </div>

        <Overlay
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          }}
          placement="bottom"
          target={buttonRef.current}
          show={show}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              ref={(e) => {
                props.ref(e);
                overlayRef.current = e;
              }}
              style={props.style}
              className="tw-pt-2.5 tw-z-1200 tw-w-full tw-max-w-xs"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <div className="tw-bg-brand-yellow tw-p-8 tw-font-styleSans tw-rounded-lg">
                <div className="tw-relative tw-mb-2.5">
                  <ChatSvg className="tw-align-middle tw-h-6 tw-w-6"/>
                  {hasUnreadMessage ? (
                    <div className="tw-bg-orange tw-h-2.5 tw-w-2.5 tw-rounded-full tw-absolute tw-top-0.5 tw-left-3.5"></div>
                  ): null}
                  <Link href={AppRoutes.chatRoute().toPath()}>
                    <a className="tw-text-brand-navy tw-font-semibold hover:tw-underline">
                      {' '}Messages
                    </a>
                  </Link>
                </div>
                <div className="tw-mb-2.5">
                  <AirplaneSvg width={24} height={24} className="tw-mr-1 tw-align-top" />
                  <Link href={AppRoutes.accountTripsRoute().toPath()}>
                    <a className="tw-text-brand-navy tw-font-semibold hover:tw-underline">
                      Bookings
                    </a>
                  </Link>
                </div>
                <div className="tw-mb-2.5">
                  <CreditCardSvg width={24} height={24} className="tw-mr-1 tw-align-top" />
                  <Link href={AppRoutes.accountGiftCodesRoute().toPath()}>
                    <a className="tw-text-brand-navy tw-font-semibold hover:tw-underline">Your Gifts</a>
                  </Link>
                </div>
                <div className="tw-mb-2.5">
                  <SpeakerSvg width={24} height={24} className="tw-mr-1 tw-align-top" />
                  <Link href={AppRoutes.accountCreditsRoute().toPath()}>
                    <a className="tw-text-brand-navy tw-font-semibold hover:tw-underline">
                      Credits & Referrals
                    </a>
                  </Link>
                </div>
                <div className="tw-border-b tw-border-grey-lighter tw-border-solid tw-my-4"></div>
                {currentUser.isPartner && (
                  <div className="tw-mb-2.5">
                    <PinPartnerSvg width={24} height={20} className="tw-mr-1 tw-align-top" />
                    <Link href={AdminRoutes.partnerRoute({}).toUrl()}>
                      <a className="tw-text-brand-navy tw-font-semibold hover:tw-underline">
                        Partner Dashboard
                      </a>
                    </Link>
                  </div>
                )}

                {currentUser.isAdmin && (
                  <div className="tw-mb-2.5">
                    <ToolSvg width={24} height={20} className="tw-mr-1 tw-align-top" />
                    <Link href={AdminRoutes.adminRoute({}).toUrl()}>
                      <a className="tw-text-brand-navy tw-font-semibold hover:tw-underline">
                        Admin Dashboard
                      </a>
                    </Link>
                  </div>
                )}
                <div className="tw-mb-2.5">
                  <User2Svg width={24} height={24} className="tw-mr-1 tw-align-top" />
                  <Link href={AppRoutes.accountProfileRoute().toPath()}>
                    <a className="tw-text-brand-navy tw-font-semibold hover:tw-underline">
                      Profile
                    </a>
                  </Link>
                </div>

                <div className="tw-cursor-pointer" onClick={() => logout()}>
                  <LogoutSvg width={24} height={20} className="tw-mr-1 tw-align-top" />
                  <span className="tw-text-brand-navy tw-font-semibold hover:tw-underline">
                    Logout
                  </span>
                </div>
              </div>
            </div>
          )}
        </Overlay>
      </div>

      <ClientOnlyPortal selector="#bottom-body">
        {isScreenMd ? (
          <div
            className={classNames(
              'tw-bg-black tw-bottom-0 tw-left-0 tw-opacity-30 tw-fixed tw-w-screen tw-z-101',
              { 'tw-hidden': !show }
            )}
            style={{ height: 'calc(100vh - 72px)' }}
          ></div>
        ) : null}
      </ClientOnlyPortal>
    </>
  ) : null;
};

export default ProfileDropdown;
