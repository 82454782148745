import { useEffect } from 'react'
import { useRouter  } from 'next/router';

const Maintenance: React.FC = () => {
  const router = useRouter()

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_MAINTENANCE_ENABLED === '1') {
      window.location.href = '/maintenance.html'
    }
  }, [router.pathname])
  return <></>
}

export default Maintenance
