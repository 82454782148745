import { useMediaQuery } from 'react-responsive';

export default function useBreakpoints() {
  const tmp = {
    isScreenXs: useMediaQuery({ minWidth: '375px' }),
    isScreenSm: useMediaQuery({ minWidth: '576px' }),
    isScreenMd: useMediaQuery({ minWidth: '769px' }),
    isScreenLg: useMediaQuery({ minWidth: '992px' }),
    isScreenXl: useMediaQuery({ minWidth: '1200px' }),
  };

  type Keys = keyof typeof tmp;

  return {
    ...tmp,
    isServer: (Object.keys(tmp) as Keys[]).every((key) => !tmp[key]),
  };
}
