import { UIDateFormat, UITimeFormat } from 'src/lib/constants'
import dayjs from 'dayjs';

export function displayTripDate(data: { startDate: string, endDate: string | null }) {
  return data.endDate && dayjs(data.endDate).isAfter(dayjs(data.startDate)) ? (
    `${dayjs(data.startDate).format(UIDateFormat)} - ${dayjs(data.endDate).format(UIDateFormat)}`
  ) : (
    dayjs(data.startDate).format(UIDateFormat)
  )
}

export function displayTripTime(data: { startTime: string | null; endTime: string | null }) {
  return `${data.startTime ? dayjs.utc(data.startTime).format(UITimeFormat) : ''}${data.endTime ? ` - ${dayjs.utc(data.endTime).format(UITimeFormat)}` : ''}`
}
