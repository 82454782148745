import { Price, Option, EnumOptionKind, EnumPriceUnit, EnumAdvancedGroupPriceType } from 'src/types';
import { compact, max } from 'lodash-es'

type FakeOption = {
  yachtTrip?: boolean;
  kind: Option['kind'];
  priceUnit: Option['priceUnit'];
  groupPriceType?: Option['groupPriceType'];
}
export const canShowAverageAdvancedGroupPrice = (option: Option | FakeOption) => {
  return (
    !option.yachtTrip &&
    option.kind === EnumOptionKind.shortAdventure &&
    option.priceUnit === EnumPriceUnit.perGroup &&
    option.groupPriceType === EnumAdvancedGroupPriceType.pricePerGroupWithAdditionalPaxAddon
  );
};

export const getMaxQuantityFromPrices = (prices: Price[]) => {
  const quantities = compact(prices.map((o) => (o.maxQtyForPrice || o.minQtyForPrice)))
  return max(quantities) || 0
}
