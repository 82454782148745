import { useRef, useEffect, useState } from 'react';
import { FiMinus, FiPlus} from 'react-icons/fi';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import classNames from 'classnames';
import styles from 'src/styles/modules/components/stepper.module.scss';

type Props = {
  value?: number | null;
  useNativeInput?: boolean;
  defaultValue?: number | null;
  minusClass?: string;
  plusClass?: string;
  inputClass?: string;
  format?: (value: number) => number;
  inputDisplayType?: 'input' | 'text';
  inputDisabled?: boolean;
  onIncrease?: () => void;
  onDecrease?: () => void;
  onChange?: (values: NumberFormatValues, inputEle?: HTMLInputElement) => void;
  onNativeChange?: (value: number) => void
};

const Stepper: React.FC<Props> = ({
  value,
  useNativeInput,
  defaultValue,
  inputDisplayType = 'input',
  inputDisabled,
  onIncrease,
  onDecrease,
  onChange,
  onNativeChange,
  format,
  inputClass,
  minusClass = 'tw-text-brand-navy',
  plusClass = 'tw-text-brand-navy',
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const [nativeValue, setNativeValue] = useState<number | undefined>(0)

  useEffect(() => {
    setNativeValue(typeof value === 'number' ? Number(value) : undefined)
  }, [value])

  return (
    <div className="tw-flex tw-items-center">
      <div className="tw-cursor-pointer">
        <span
          className={`tw-text-xl tw-font-styleSans tw-font-semibold tw-inline-block tw-h-6 tw-px-3 tw-text-center tw-leading-6 ${minusClass}`}
          onClick={() => {
            onDecrease && onDecrease();
          }}
        >
          -
        </span>
      </div>
      <div>
        {useNativeInput ? (
          <input
            type="number"
            min={0}
            step={1}
            value={nativeValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value
                ? format
                  ? format(Number(e.target.value))
                  : Number(e.target.value)
                : undefined;
              onNativeChange && onNativeChange(value || 0);
              setNativeValue(value);
            }}
            className={classNames(
              `tw-w-10 tw-p-1 tw-h-6 tw-text-center form-control tw-leading-none ${styles.input}`,
              {
                'tw-border-grey-lighter': !value,
                'tw-border-brand-navy': !!value,
              }
            )}
          />
        ) : (
          <NumericFormat
            getInputRef={(el: HTMLInputElement) => (inputRef.current = el)}
            disabled={inputDisabled}
            allowNegative={false}
            displayType={inputDisplayType}
            className={classNames(
              `tw-w-10 tw-p-1 tw-h-6 tw-text-center tw-rounded-lg tw-leading-none ${styles.input}`,
              {
                'form-control': inputDisplayType === 'input',
                'tw-border-grey-lighter': !value,
                'tw-border-brand-navy': !!value,
                [inputClass as string]: !!inputClass,
              }
            )}
            decimalScale={0}
            value={value || 0}
            defaultValue={defaultValue}
            onValueChange={(values) => {
              onChange && onChange(values, inputRef.current);
            }}
          />
        )}
      </div>
      <div className="tw-cursor-pointer">
        <span
          className={`tw-text-xl tw-font-styleSans tw-font-semibold tw-inline-block tw-h-6 tw-px-3 tw-text-center tw-leading-6 ${plusClass}`}
          onClick={() => {
            onIncrease && onIncrease();
          }}
        >
          +
        </span>
      </div>
    </div>
  );
};

export default Stepper;
