import { uniq } from 'lodash-es';

const SEPARATOR = '_ss_'

export function getSearchHistory(): string[] {
  if (typeof window === 'object') {
    return (window.localStorage.getItem('search_history') || '').split(SEPARATOR).filter(v => v)
  } else {
    return []
  }
}

export function updateSearchHistory(item: string) {
  if (typeof window === 'object') {
    let searchHistory = getSearchHistory()
    searchHistory.unshift(item)
    searchHistory = uniq(searchHistory)
    window.localStorage.setItem('search_history', searchHistory.slice(0, 8).join(SEPARATOR))
  }
}

export function clearSearchHistory() {
  if (typeof window === 'object') {
    window.localStorage.removeItem('search_history')
  }
}
