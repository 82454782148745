import React, { useState } from 'react';

export type ConfirmDialogProps = {
  title: string;
  body: string;
  okText: string;
  cancelText: string;
  resolve: (value?: unknown) => void;
  reject: (value?: unknown) => void;
};

type TConfirmDialogContext = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  data: ConfirmDialogProps;
  setData: React.Dispatch<React.SetStateAction<ConfirmDialogProps>>;
};

export const ConfirmDialogContext = React.createContext<TConfirmDialogContext>({
  show: false,
  setShow: () => {},
  data: {} as ConfirmDialogProps,
  setData: () => {},
});

const ConfirmDialogProvider: React.FC = ({ children }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState<ConfirmDialogProps>({
    title: '',
    body: '',
    okText: '',
    cancelText: '',
    resolve: () => {},
    reject: () => {},
  });

  return (
    <ConfirmDialogContext.Provider value={{ show, setShow, data, setData }}>
      {children}
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;
