import React, { useState } from 'react';
import * as yup from 'yup';
import classNames from 'classnames';
import ApiClient from 'src/lib/api_client';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';

const EmailValidation = yup
  .string()
  .email('Email is invalid')
  .required('Please enter email')
  .typeError('Please enter email');

type Props = {
  onSuccess?: () => void;
};

const SubscribeNewsleter: React.FC<Props> = ({ onSuccess }) => {
  const subscribe = useEnhanceApiClient(ApiClient.subscribeNewsletter);
  const [msg, setMsg] = useState('');
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  return (
    <div>
      <div className="tw-border tw-border-brand-navy tw-rounded-4xl tw-p-1 tw-flex tw-items-center">
        <input
          type="email"
          value={email}
          className="tw-w-full tw-appearance-none tw-bg-transparent tw-ml-4 lg:tw-ml-6 tw-mr-2 tw-font-semibold tw-text-brand-navy tw-outline-none tw-placeholder-current tw-placeholder-opacity-100"
          placeholder="email address"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
          }}
        />
        <button
          type="button"
          className="btn btn-primary tw-rounded-4xl tw-px-6 tw-py-2 lg:tw-px-8 lg:tw-py-3"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            const button = e.currentTarget;
            button.disabled = true;

            try {
              EmailValidation.validateSync(email);
            } catch (err) {
              if (err instanceof Error) {
                setMsg(err.message);
              }
              button.disabled = false;
              return;
            }

            subscribe({ email: email })
              .then((result) => {
                const [json] = result
                if ('error' in json) {
                  setIsSuccess(false);
                  setIsError(true);
                  setMsg(json.error);
                } else {
                  setIsSuccess(true);
                  setIsError(false);
                  if (json.status === 'subscribed' || json.status === 'existed') {
                    setMsg("You're already a subscriber");
                  } else {
                    if (typeof onSuccess === 'function') {
                      onSuccess();
                    } else {
                      setMsg('Successfully subscribed!');
                    }
                  }
                }
              })
              .finally(() => (button.disabled = false));
          }}
        >
          subscribe
        </button>
      </div>
      {msg ? (
        <div
          className={classNames('tw-text-sm tw-mt-1', {
            'tw-text-green': isSuccess,
            'tw-text-red': isError,
          })}
        >
          {msg}
        </div>
      ) : null}
    </div>
  );
};

export default SubscribeNewsleter;
