import React, { useState } from 'react';

type ControlHeaderPageNavProps = {
  showHeader: boolean;
  showFooter: boolean;
  setShowHeader: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ControlHeaderPageNavContext = React.createContext<ControlHeaderPageNavProps>({
  showHeader: true,
  showFooter: true,
  setShowHeader: () => {},
  setShowFooter: () => {}
});

const ControlHeaderPageNavProvider: React.FC = ({ children }) => {
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  return (
    <ControlHeaderPageNavContext.Provider value={{ showHeader, setShowHeader, showFooter, setShowFooter }}>
      {children}
    </ControlHeaderPageNavContext.Provider>
  );
};

export default ControlHeaderPageNavProvider;
