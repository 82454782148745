import { useContext, useCallback } from 'react'
import { ConfirmDialogContext, ConfirmDialogProps } from 'src/contexts/ConfirmDialogProvider'

export default function useConfirmDialog () {
  const { setData, setShow } = useContext(ConfirmDialogContext)

  const func = useCallback((data: Omit<ConfirmDialogProps, 'resolve' | 'reject'>) => {
    setShow(true)

    return new Promise(function (resolve, reject) {
      setData({ ...data, resolve, reject })
    })
  }, [setData, setShow])

  return func
}

