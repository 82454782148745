type Event = {
  eventId?: string;
  eventName?: string;
  contentId: number | string;
  contentType?: 'product' | 'product_group';
  contentName: string;
  contentCategory?: string;
  contentCategory2?: string;
  contentBrand?: string;
  currency?: string;
  value?: number;
  price?: number;
  quantity?: number;
  query?: string;
  description?: string;
  status?: string;
  customer?: CustomerParams;
}

type CustomerParams = {
  name: string;
  email: string;
  phoneNumber: string;
  country: string;
}

interface Notifer {
  sendPageView: (prevUrl?: string) => void;
  sendViewContent: (event: Event) => void;
  sendAddToCart: (event: Event) => void;
  sendPurchase: (event: Event) => void;
  setUserData?: (event: Event) => void;
}

class GoogleAnalyticNotifier implements Notifer {
  sendPageView(prevUrl?: string | null) {
    if (prevUrl) {
      window.gtag('set', {
        referrer: prevUrl
      })
    }

    window.gtag('event', 'page_view', {
      page_title: window.document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_referrer: prevUrl
    })
  }

  sendViewContent(event: Event) {
    window.gtag('event', 'view_item', {
      event_label: event.eventName,
      currency: event.currency,
      items: [{
        item_id: event.contentId,
        item_name: event.contentName,
        item_brand: event.contentBrand,
        item_category: event.contentCategory,
        item_category2: event.contentCategory2,
        price: event.price,
        currency: event.currency
      }]
    })
  }

  sendAddToCart(event: Event) {
    window.gtag('event', 'add_to_cart', {
      event_label: event.eventName,
      value: event.value,
      currency: event.currency,
      items: [{
        item_id: event.contentId,
        item_name: event.contentName,
        item_brand: event.contentBrand,
        item_category: event.contentCategory,
        item_category2: event.contentCategory2,
        quantity: event.quantity,
        price: event.price
      }]
    })
  }

  sendPurchase(event: Event) {
    window.gtag('event', 'purchase', {
      send_to: process.env.NEXT_PUBLIC_GOOGLE_GA4_TAG_ID,
      event_label: event.eventName,
      value: event.value,
      currency: event.currency,
      transaction_id: event.eventId,
      items: [{
        item_id: event.contentId,
        item_name: event.contentName,
        item_brand: event.contentBrand,
        item_category: event.contentCategory,
        item_category2: event.contentCategory2,
        quantity: event.quantity,
        price: event.price
      }]
    })
  }

  sendPurchaseAdsConversion(event: Event) {
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') return
    const adsConversionId = `${process.env.NEXT_PUBLIC_GOOGLE_ADWORD_ID}/${process.env.NEXT_PUBLIC_GOOGLE_ADS_CONVERSION_LABEL}`
    window.gtag('event', 'conversion', {
      send_to: adsConversionId,
      value: event.value,
      currency: event.currency,
      transaction_id: event.eventId
    })
    window.gtag('event', 'purchase', {
      send_to: adsConversionId,
      value: event.value,
      currency: event.currency,
      transaction_id: event.eventId,
      items: [{
        item_id: event.contentId,
        quantity: event.quantity,
        price: event.price
      }]
    })
  }

  sendSearch(text: string) {
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') return
    window.gtag('event', 'search', {
      search_term: text
    })
  }

  setCampaign(utm: {
    name: string | null,
    source: string | null,
    medium: string | null
  }) {
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') return
    const isValid = utm.name || utm.source || utm.medium
    if (!isValid) return
    window.gtag('set', {
      campaign_name: utm.name,
      campaign_source: utm.source,
      campaign_medium: utm.medium,
    })
  }

  setUserData(event: Event) {
    if (!event.customer) return
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') return
    window.gtag('set', 'user_data', {
      email: event.customer.email,
      phone_number: event.customer.phoneNumber,
      address: {
        first_name: event.customer.name,
        last_name: '',
        street: '',
        city: '',
        region: '',
        postal_code: '',
        country: event.customer.country
      }
    });
  }
}

class FacebookNotifier implements Notifer {
  sendPageView() {
    window.fbq('track', 'PageView')
  }

  sendViewContent(event: Event) {
    window.fbq('track', 'ViewContent', {
      content_ids: [String(event.contentId)],
      content_name: event.contentName
    }, { eventID: event.eventId })
  }

  sendAddToCart(event: Event) {
    window.fbq('track', 'AddToCart', {
      currency: event.currency,
      value: event.value,
      content_ids: [String(event.contentId)],
      content_name: event.contentName
    }, { eventID: event.eventId })
  }

  sendPurchase(event: Event) {
    window.fbq('track', 'Purchase', {
      currency: event.currency as string,
      value: event.value as number,
      content_ids: [String(event.contentId)],
      content_name: event.contentName,
      order_id: event.eventId,
      num_items: event.quantity
    }, { eventID: event.eventId })
  }
}

class TiktokNotifier implements Notifer {
  sendPageView() {
    window.ttq?.page()
  }

  sendViewContent(event: Event) {
    window.ttq?.track('ViewContent', {
      content_id: event.contentId,
      content_name: event.contentName,
      content_type: 'product',
      content_category: event.contentCategory,
      price: event.price,
      currency: event.currency
    })
  }

  sendAddToCart(event: Event) {
    if (!window.ttq) return
    window.ttq.track('AddToCart', {
      content_id: event.contentId,
      content_name: event.contentName,
      content_type: 'product',
      content_category: event.contentCategory,
      currency: event.currency,
      value: event.value
    }, { event_id: event.eventId })
  }

  sendPurchase(event: Event) {
    if (!window.ttq) return
    window.ttq.track('PlaceAnOrder', {
      content_id: event.contentId,
      content_name: event.contentName,
      content_type: 'product',
      content_category: event.contentCategory,
      currency: event.currency,
      value: event.value
    }, { event_id: event.eventId })
  }
}


export const googleNotifier = new GoogleAnalyticNotifier
export const facebookNotifier = new FacebookNotifier
export const tiktokNotifier = new TiktokNotifier

const notifiers = {
  'google': googleNotifier,
  'facebook': facebookNotifier,
  'tiktok': tiktokNotifier,
}

function notify(
  provider: 'google' | 'facebook' | 'tiktok',
  eventName: 'PageView' | 'AddToCart' | 'Purchase' | 'ViewContent',
  event?: Event | string | null
) {
  if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') return
  const notifier = notifiers[provider]

  if (eventName === 'PageView') {
    if (typeof event !== 'object') {
      notifier.sendPageView(event)
    } else {
      notifier.sendPageView()
    }
  } else if (event && typeof event === 'object') {
    notifier[`send${eventName}`](event)
  }
}

export default notify;
