import { useContext } from 'react';
import { TooManyRequestsErrorContext } from 'src/contexts/TooManyRequestsErrorProvider';

type EnhanceApiClient = <T extends (...args: any) => Promise<[any, Response]>>(
  request: T
) => (
  params?: Parameters<T>[0],
  headers?: Parameters<T>[1]
) => ReturnType<T> extends Promise<[infer R, Response]> ? Promise<[R, Response]> : Promise<any>;

const useEnhanceApiClient: EnhanceApiClient = (request) => {
  const [, setIsError] = useContext(TooManyRequestsErrorContext);
  return (params, headers) =>
    request(params, headers).then((result) => {
      const [, response] = result
      if (response.status === 429) {
        setIsError(true);
      }
      return result
    });
};

export default useEnhanceApiClient;
