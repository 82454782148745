import React, { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import ClientOnlyComponent from 'src/components/ClientOnlyComponent';
import { Modal } from 'react-bootstrap';
import SubscribeNewsletter from './SubscribeNewsletter';
import ImageWithFallback from 'src/components/ImageWithFallback';
import HeroImage from 'public/images/home/hero_0.jpg';
import Subscription from 'public/images/subscription.png';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useInitialData from 'src/hooks/useInitialData';
import { TMoney } from 'src/types';
import Money from 'src/components/Money';

const HIDDEN_SUBSCRIPTION_POPUP = 'hidden_subscription_poup';
const COUNTRY_CODE_TO_AMOUNT: { [key: string]: TMoney } = {
  MY: { amount: 30, currency: 'MYR' },
  SG: { amount: 10, currency: 'SGD' },
};
const USD_AMOUNT: TMoney = { amount: 10, currency: 'USD' }

const SubscriptionEmailPopup = () => {
  const [currentUser] = useCurrentUser();
  const { currentGeolocation } = useInitialData();
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [amount, setAmount] = useState<TMoney>(USD_AMOUNT);

  useEffect(() => {
    if (currentGeolocation.countryCode) {
      const discountAmount = COUNTRY_CODE_TO_AMOUNT[currentGeolocation.countryCode] || USD_AMOUNT
      setAmount(discountAmount)
    }
  }, [currentGeolocation.countryCode]);

  useEffect(() => {
    let timer = setTimeout(() => {
      const hiddenSubscriptionPopup = window.localStorage.getItem(HIDDEN_SUBSCRIPTION_POPUP);

      if (!currentUser && !hiddenSubscriptionPopup) {
        setShowModal(true);
        window.localStorage.setItem(HIDDEN_SUBSCRIPTION_POPUP, 'true');
      }
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ClientOnlyComponent>
      <>
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          centered={true}
          className="modal-styled"
          dialogClassName="modal-dialog-centered modal-full-width--sp modal-dialog-scrollable modal-lg"
          contentClassName="tw-rounded-none modal-styled tw-bg-transparent tw-border-0"
        >
          <Modal.Body>
            <div className="row row-original tw-h-96">
              <div className="col-lg-8 md:tw-order-1 tw-order-2 tw-flex tw-items-center tw-justify-center tw-p-6 tw-bg-brand-yellow md:tw-rounded-tl-lg md:tw-rounded-bl-lg md:tw-rounded-br-none tw-rounded-bl-lg tw-rounded-br-lg">
                <div className="tw-text-center">
                  <div className="tw-font-styleSans tw-text-xl md:tw-text-4xl tw-font-semibold">
                    {subscribed ? 'Yay, you’re in!' : 'Skip the tourist traps 🤫'}
                  </div>

                  {subscribed ? (
                    <div className="tw-mt-8 tw-pb-4">
                      Welcome to the Secret Seekers’ society.
                      <br /> Check your email for your welcome gift!
                    </div>
                  ) : (
                    <>
                      <div className="tw-mt-8">
                        Get local tips and hidden gems <br /> delivered to your inbox.
                      </div>
                      <div className="tw-mt-4 tw-mb-8">
                        (Oh, and as a <strong>welcome gift</strong>, you’ll get <br />
                        <Money amount={amount}></Money> off your next adventure!)
                      </div>
                      <div className="tw-pb-4">
                        <SubscribeNewsletter
                          onSuccess={() => {
                            setSuccess(true);
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4 tw-p-0 md:tw-order-2 tw-order-1 md:tw-h-96 tw-h-56 tw-relative">
                <span
                  className="tw-cursor-pointer tw-absolute tw-top-2 tw-right-4"
                  onClick={() => setShowModal(false)}
                >
                  <FiX strokeWidth={2} size={24} className="tw-text-brand-navy" />
                </span>
                <ImageWithFallback
                  src={success ? Subscription : HeroImage}
                  onLoad={() => {
                    if (success) {
                      setSubscribed(true);
                    }
                  }}
                  alt=""
                  className="tw-w-full tw-h-56 md:tw-h-96 tw-static tw-block tw-object-cover md:tw-rounded-tr-lg md:tw-rounded-br-lg md:tw-rounded-tl-none tw-rounded-tr-lg tw-rounded-tl-lg"
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </ClientOnlyComponent>
  );
};

export default SubscriptionEmailPopup;
