import { useRouter } from 'next/router';
import { useEffect, useContext } from 'react';
import { ActionCableContext } from 'src/contexts/ActionCableProvider';
import { ControlHeaderPageNavContext } from 'src/contexts/ControlHeaderPageNavProvider';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

const chatPaths = [
  '/chat/[id]',
  '/chat'
]

const ClearChatStates: React.FC = () => {
  const router = useRouter();
  const actionCableConsumer = useContext(ActionCableContext)
  const { setShowFooter } = useContext(ControlHeaderPageNavContext);

  useEffect(() => {
    if (router.isReady) {
      const onRouteChangeComplete = () => {
        if (!chatPaths.includes(router.pathname) || router.pathname === '/') {
          if (actionCableConsumer) {
            actionCableConsumer.disconnect()
          }
          setShowFooter(true);
          clearAllBodyScrollLocks()
        }
      };

      router.events.on('routeChangeComplete', onRouteChangeComplete);

      return () => {
        router.events.off('routeChangeComplete', onRouteChangeComplete);
      }
    }
  }, [router, actionCableConsumer]);

  return (
    <></>
  )
}

export default ClearChatStates
