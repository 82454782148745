import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { TooManyRequestsErrorContext } from 'src/contexts/TooManyRequestsErrorProvider';

type Props = {}

const TooManyRequestsErrorPopup: React.FC<Props> = () => {
  const [isError] = React.useContext(TooManyRequestsErrorContext)
  const [isShow, setIsShow] = React.useState(false)

  useEffect(() => {
    setIsShow(isError)
  }, [isError])

  return (
    <Modal show={isShow} className="modal-styled" backdrop="static" keyboard={false}>
      <Modal.Header>
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
          <div className="tw-text-xl md:tw-text-4xl tw-font-semibold">
            Let{"'"}s take a break
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-text-lg">You are browsing the site too quick. Please try again after a few minutes</div>
      </Modal.Body>
    </Modal>
  )
}

export default TooManyRequestsErrorPopup
