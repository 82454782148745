import React, { useState } from 'react';

export const FilterItemContext = React.createContext<{
  showFilterItem: boolean;
  setShowFilterItem: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  showFilterItem: false,
  setShowFilterItem: () => {}
})

const FilterItemProvider: React.FC = ({ children }) => {
  const [showFilterItem, setShowFilterItem] = useState(false)

  return (
    <FilterItemContext.Provider value={{ showFilterItem, setShowFilterItem }}>
      {children}
    </FilterItemContext.Provider>
  )
}

export default FilterItemProvider
