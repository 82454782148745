import { useContext } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import humps, { Camelized } from 'humps';
import { omit } from 'lodash-es';
import corsFetch from 'src/lib/cors_fetch';
import { TooManyRequestsErrorContext } from 'src/contexts/TooManyRequestsErrorProvider';

type Fetcher = <T>(
  options: RequestInit & { corsEnable?: boolean; handleTooManyReqError?: () => void }
) => (url: string) => Promise<Camelized<T>>;

export class ApiError extends Error {
  public status: number = 500;

  constructor(message: string) {
    super(message);
  }
}

export const camelizedFetcher: Fetcher = (options) => (url) => {
  const finalFetch = options.corsEnable ? corsFetch : fetch;
  const fetchOptions = omit(options, ['corsEnable']);
  return finalFetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_SS_API_ACCESS_TOKEN}`,
      credentials: 'include',
      ...(fetchOptions.headers || {}),
    },
    ...omit(fetchOptions, ['headers']),
  })
    .then(async (res) => {
      const json = await res.json();
      if (res.ok) {
        return json;
      } else if (json.error) {
        if (res.status === 429 && options.handleTooManyReqError) {
          options.handleTooManyReqError();
        }
        const error = new ApiError(json.error);
        error.status = res.status;
        throw error;
      } else {
        const error = new ApiError('An internal error occurred');
        error.status = 500;
        throw error;
      }
    })
    .then((json) => humps.camelizeKeys(json));
};

function useQuery<T>(
  key: string | null,
  options: SWRConfiguration & {
    fetcherOpts?: RequestInit & { corsEnable: boolean };
  } = {}
) {
  const [, setIsTooManyReqError] = useContext(TooManyRequestsErrorContext);
  const result = useSWR<Camelized<T>, ApiError, string | null>(
    key,
    camelizedFetcher<T>({
      handleTooManyReqError: () => setIsTooManyReqError(true),
      ...(options.fetcherOpts || {}),
    }),
    {
      dedupingInterval: 20000,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      ...omit(options, ['fetcherOpts']),
    }
  );

  return result;
}

export default useQuery;
