const CORS_FETCH_CONFIG: RequestInit = {
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  redirect: 'follow',
  referrer: 'no-referrer',
  credentials: 'include',
};

export default function corsFetch(input: RequestInfo, init: RequestInit) {
  return fetch(input, {
    ...CORS_FETCH_CONFIG,
    ...init,
    headers: {
      ...CORS_FETCH_CONFIG.headers,
      ...(init.headers || {})
    },
  });
}
