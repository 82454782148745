import { useContext } from 'react';
import { SearchBarStateContext, SearchBarContext } from 'src/contexts/SearchBarStateProvider';

const DEFAULT_VALUE = { qs: '', numGuest: null, numAdult: null, numChild: null, dates: [] };

export default function useSearchBarState(): SearchBarContext {
  const [params, setParams, initialParams] = useContext(SearchBarStateContext);
  return [
    {
      qs: params.qs || DEFAULT_VALUE.qs,
      numGuest: params.numGuest || DEFAULT_VALUE.numGuest,
      numChild: params.numChild || DEFAULT_VALUE.numChild,
      numAdult: params.numAdult || DEFAULT_VALUE.numAdult,
      dates: params.dates || DEFAULT_VALUE.dates,
    },
    setParams,
    {
      qs: initialParams.qs || DEFAULT_VALUE.qs,
      numGuest: initialParams.numGuest || DEFAULT_VALUE.numGuest,
      numChild: initialParams.numChild || DEFAULT_VALUE.numChild,
      numAdult: initialParams.numAdult || DEFAULT_VALUE.numAdult,
      dates: initialParams.dates || DEFAULT_VALUE.dates,
    }
  ];
}
